body{
    font-family: 'poppins';
    .container{
        margin-top: 50px;
        margin-bottom: 100px;
        .row{
            padding-bottom: 20px;        
            height: auto;
            .center{
                display:flex;
                justify-content:center;
                align-items:center;
                .action{
                    margin-top: 10px;
                    padding: 10px 5px;
                    width: 170px;
                }
                a{
                    color: white;
                    text-decoration: none;
                }
            }
            .imageDiv{
                height:300px;
                width: auto;
                display:flex;
                align-items:center;
                justify-content: center;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                img{
                   max-width: 100%;
                }
            }
            .textDiv{
                height:300px;
                width: auto;
                display:flex;
                align-items:center;
                justify-content: center;
                background-color: #E6E6E6;
                font-weight: normal;
                @media screen and (max-width: 992px) {
                    height: auto;
                    padding: 10px 0;
                }
            }
            .left{
                border-radius: calc(.4rem - 1px) 0 0 calc(.4rem - 1px);
            }
            .right{
                border-radius: 0 calc(.4rem - 1px) calc(.4rem - 1px) 0;
            }
            .img1{
                background-image: url('../../assets/WalterPeak2.png');
            }
            .img2{
                background-image: url('../../assets/Brisbane.jpg');
            }

            
        }
        .jumbotron{
            background-color: #E6E6E6;
        }
    }
    
    
}

