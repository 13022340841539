body{
    font-family: 'poppins';
    .container{
        font-weight: 700;
        margin-top: 40px;
        margin-bottom: 0px;
        .row{
            margin: 0;
            .col{
                display:flex;
                align-items:center;
                text-align: left;
            }
            h1{
                color: #369682;
                font-size: 2.6em;
                position: relative;
            }
            h2{
                font-size: 1.6em;
            }
            padding-bottom: 20px;        
            height: auto;        
            .form-row{
                width: 100%;
            }
        }

    }
    
}

