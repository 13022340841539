body{
    font-family: 'poppins';
    .container{
        margin-top: 50px;
        margin-bottom: 100px;
        .row{
            padding-bottom: 20px;        
            height: auto;
            .icon{
                height:150px;
                width: 35%;
                display:flex;
                align-items:center;
                justify-content: center;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                img{
                    max-height: 100%;
                }
            }
            .description{
                height:150px;
                width:auto;
                display:flex;
                flex-direction: column;
                justify-content: flex-start;
                background-color: #E6E6E6;
                align-items: flex-start;
                font-weight: normal;
                @media screen and (max-width: 992px) {
                    height: auto;
                    padding: 10px;
                }
            }
            .left{
                border-radius: calc(.4rem - 1px) 0 0 calc(.4rem - 1px);
            }
            .right{
                border-radius: 0 calc(.4rem - 1px) calc(.4rem - 1px) 0;
            }
            .imgDice{
                background-image: url('../../assets/2-Dice-Icon.png');
            }
        }
    }
}