@import "~bootstrap/scss/bootstrap";

html{
    background-color: #f8f8ff;
    .nav{
        padding: .5rem 1rem;
    }
    nav{
        img{
            height: 30px;
            width: 30px;
        }
    }
    .btn-primary{
            background-color: #369682;
            border-color: #369682;
    }
}