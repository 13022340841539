.app {
  text-align: center;
  background-color: #f8f8ff;
  
  .App-header {
    @media only screen and (max-width: 768px){
      min-height: 100vh;
    }
    background-color: #282c34;
    background-image: url("../../assets/wall2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    .chevronContainer{
      display:flex;
      justify-content:center;
      align-items:flex-end;  
      .center{
        position: absolute;
        bottom: 51%;
        @media only screen and (max-width: 768px){
          bottom: 10%;
        }
        button{
          background:none;
          border:none;
          color:white;
          font-weight: bold;
        }
        .scroller {
          text-align: center;
          position: absolute;
          bottom: 5%;
          margin: 0 auto;
          left: -27px;
          right: 0;
          cursor: pointer;
          font-family: Poiret One, cursive;
        }
      }
    }
  }
  .nav{
    background-color: #f8f8ff;
  }  
}

.App-link {
  color: #61dafb;
}